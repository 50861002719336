/**
 * Turkish
 */
export const tr = {
    'Transaction History': 'İşlem Geçmişi',
    'Total Debit': 'Toplam Borç',
    'Total Credit': 'Toplam Alacak',
    'Net Balance': 'Net Bakiye',
    'Date': 'Tarih',
    'Debit': 'Borç',
    'Credit': 'Alacak',
    'Balance': 'Bakiye',
    'Customer will give': 'Müşteri verecek',
    'Customer will get': 'Müşteri Alacak',
    'Dr': 'Borç',
    'Cr': 'Alacak',
    'Latest': 'En Yeni',
    'Months': {
        "Jan": "Oca",
        "Feb": "Şub",
        "Mar": "Mar",
        "Apr": "Nis",
        "May": "May",
        "Jun": "Haz",
        "Jul": "Tem",
        "Aug": "Ağu",
        "Sep": "Eyl",
        "Oct": "Eki",
        "Nov": "Kas",
        "Dec": "Ara"
    },
    'Call': 'Ara',
    'Whats app': 'Whatsapp',
    'Pay Online':'Çevrimiçi Öde',
    'Owed': 'borçlu',
    'Edit': 'Düzenlemek',
    'Update': 'Güncelleme',
    'Remaining': 'Geriye kalan',
    'Pay with Card': 'Kartla Öde',
    'Pay with Bank': 'Banka ile öde',
    'Pay': 'Ödemek',
    'Pay by IBAN': 'IBAN ile ödeme',
};