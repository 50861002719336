/**
 * French
 */
export const fr = {
    'Transaction History': 'Transaction Historique',
    'Total Debit': 'Total Debit',
    'Total Credit': 'Total Credit',
    'Net Balance': 'Montant Net',
    'Date': 'Date',
    'Debit': 'Debit',
    'Credit': 'Credit',
    'Balance': 'Balance Net',
    'Customer will give': 'Le client doit',
    'Customer will get': 'Le client va recevoir',
    'Dr': 'Debit',
    'Cr': 'Credit',
    'Latest': 'Le plus recent',
    'Months': {
        "Jan": "Jan",
        "Feb": "Fev",
        "Mar": "Mar",
        "Apr": "Avr",
        "May": "Mai",
        "Jun": "Juin",
        "Jul": "Jui",
        "Aug": "Aout",
        "Sep": "Sept",
        "Oct": "Oct",
        "Nov": "Nov",
        "Dec": "Dec"
    },
    'Call':'Appel',
    'Whats app':'Whatsapp',
    'Pay Online': 'Payer en ligne',
    'Owed': 'Dû',
    'Edit': 'Éditer',
    'Update': 'Mettre à jour',
    'Remaining': 'Restante',
    'Pay with Card': 'Payer par carte',
    'Pay with Bank': 'Payer avec la banque',
    'Pay': 'Payer',
    'Pay by IBAN': 'Payer par IBAN',
};