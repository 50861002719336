/**
 * Hungarian
 */
export const hu = {
    'Transaction History': 'Tranzakció előzmények',
    'Total Debit': 'Összes Tartozás',
    'Total Credit': 'Összes Követelés',
    'Net Balance': 'Nettó Egyenleg',
    'Date': 'Dátum',
    'Debit': 'Tartozás',
    'Credit': 'Követelés',
    'Balance': 'Egyenleg',
    'Customer will give': 'Az ügyfél adni fog',
    'Customer will get': 'Az ügyfél kapni fog',
    'Dr': 'Tartozás',
    'Cr': 'Követelés',
    'Latest': 'Legutóbbi',
    'Months': {
        "Jan": "Jan",
        "Feb": "Feb",
        "Mar": "Már",
        "Apr": "Ápr",
        "May": "Máj",
        "Jun": "Jún",
        "Jul": "Júl",
        "Aug": "Aug",
        "Sep": "Szep",
        "Oct": "Okt",
        "Nov": "Nov",
        "Dec": "Dec"
    },
    'Call':'Hívás',
    'Whats app':'Whatsapp',
    'Pay Online': 'Tranzakciók előzményei',
    'Owed': 'Tartozott',
    'Edit': 'Szerkesztés',
    'Update': 'Frissítés',
    'Remaining': 'Többi',
    'Pay with Card': 'Fizetés kártyával',
    'Pay with Bank': 'Fizetés bankkal',
    'Pay': 'Fizetés',
    'Pay by IBAN': 'Fizetés IBAN-nal',
};