/**
 * Arabic
 */
export const ar = {
    'Transaction History': 'تاريخ المعاملات',
    'Total Debit': 'إجمالي الحساب',
    'Total Credit': 'إجمالي الدفعات',
    'Net Balance': 'الرصيد الصافي',
    'Date': 'التاريخ',
    'Debit': 'دين',
    'Credit': 'دفعة',
    'Balance': 'الرصيد',
    'Customer will give': 'العميل سيدفع',
    'Customer will get': 'العميل سيتلقى',
    'Dr':'دين',
    'Cr':'دفعة',
    'Latest': 'آخر عملية',
    'Months': {
        "Jan": "يناير",
        "Feb": "فبراير",
        "Mar": "مارس",
        "Apr": "أبريل",
        "May": "مايو",
        "Jun": "يونيو",
        "Jul": "تموز",
        "Aug": "أغسطس",
        "Sep": "سبتمبر",
        "Oct": "اكتوبر",
        "Nov": "نوفمبر",
        "Dec": "ديسمبر"
    },
    'Call':'الاتصال',
    'Whats app':'واتساب',
    'Pay Online': 'ادفع عبر الإنترنت',
    'Owed': 'المستحقة',
    'Edit': 'يحرر',
    'Update': 'تحديث',
    'Remaining': 'متبقي',
    'Pay with Card': 'الدفع بالبطاقة',
    'Pay with Bank': 'ادفع مع البنك',
    'Pay': 'يدفع',
    'Pay by IBAN': 'ادفع عن طريق رقم الحساب',
}