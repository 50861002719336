<template>
  <div
    :style="{
      overflow: showloading ? 'hidden' : '',
      'max-height': showloading ? '100vh' : 'initial',
    }"
  >
    <router-view></router-view>
    <div
      v-show="showloading"
      style="
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: gray;
        z-index: 100000;
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
        style="z-index: 100001; width: 100px; height: 100px"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    // a computed getter
    showloading: function () {
      // `this` points to the vm instance
      return (
        this.$store.state.loading1 ||
        this.$store.state.loading2 ||
        this.$store.state.loading3 ||
        this.$store.state.loading4
      );
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
