import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
//import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
// Registering a single component

import { iconsSet as icons } from './assets/icons/icons.js'
import { BootstrapVue } from 'bootstrap-vue'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

import { en } from './lang/en.js';
import { ar } from './lang/ar.js';
import { fr } from './lang/fr.js';
import { de } from './lang/de.js';
import { hu } from './lang/hu.js';
import { ro } from './lang/ro.js';
import { tr } from './lang/tr.js';
const messages = {
  en,
  ar,
  fr,
  de,
  hu,
  ro,
  tr,
};

const i18n = new VueI18n({
  locale: 'en',
  messages
});

//import { firestorePlugin } from 'vuefire'
//Vue.use(firestorePlugin)

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
