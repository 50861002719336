/**
 * English
 */
export const en = {
    'Transaction History': 'Transaction History',
    'Total Debit': 'Total Debit',
    'Total Credit': 'Total Credit',
    'Net Balance': 'Net Balance',
    'Date': 'Date',
    'Debit': 'Debit',
    'Credit': 'Credit',
    'Balance': 'Balance',
    'Customer will give': 'Customer will give',
    'Customer will get': 'Customer will get',
    'Dr': 'Dr',
    'Cr': 'Cr',
    'Latest': 'Latest',
    'Months': {
        "Jan": "Jan",
        "Feb": "Feb",
        "Mar": "Mar",
        "Apr": "Apr",
        "May": "May",
        "Jun": "Jun",
        "Jul": "Jul",
        "Aug": "Aug",
        "Sep": "Sep",
        "Oct": "Oct",
        "Nov": "Nov",
        "Dec": "Dec"
    },
    'Call':'Call',
    'Whats app':'Whats app',
    'Pay Online': 'Pay Online',
    'Owed': 'Owed',
    'Edit': 'Edit',
    'Update': 'Update',
    'Remaining': 'Remaining',
    'Pay with Card': 'Pay with Card',
    'Pay with Bank': 'Pay with Bank',
    'Pay': 'Pay',
    'Pay by IBAN': 'Pay by IBAN',
};