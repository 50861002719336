import Vue from 'vue'
import Router from 'vue-router'

const CustomerDetails = () => import('@/views/customers/CustomerDetails')
const InvoiceDetails = () => import('@/views/invoice/InvoiceDetails')
const ThankYou = () => import('@/views/customers/ThankYou')
const Home = () => import('@/views/home/Home')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [   
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/details/:customer_docid',
      name: 'CustomerDetails',
      component: CustomerDetails
    },
    {
      path: '/invoices/:iid',
      name: 'InvoiceDetails',
      component: InvoiceDetails
    },
    {
      path: '/thankyou/:transactionID',
      name: 'ThankYou',
      component: ThankYou
    },
  ]
}

