/**
 * Romanian
 */
export const ro = {
    'Transaction History': 'Istoricul Tranzacțiilor',
    'Total Debit': 'Debit Total',
    'Total Credit': 'Credit Total',
    'Net Balance': 'Sold Net',
    'Date': 'Data',
    'Debit': 'Debit',
    'Credit': 'Credit',
    'Balance': 'Sold',
    'Customer will give': 'Clientul va plăti',
    'Customer will get': 'Clientul va primi',
    'Dr': 'Debit',
    'Cr': 'Credit',
    'Latest': 'Ultimele',
    'Months': {
        "Jan": "Ian",
        "Feb": "Feb",
        "Mar": "Mar",
        "Apr": "Apr",
        "May": "Mai",
        "Jun": "Iu",
        "Jul": "Iul",
        "Aug": "Aug",
        "Sep": "Sep",
        "Oct": "Oct",
        "Nov": "Noi",
        "Dec": "Dec"
    },
    'Call':'Apel',
    'Whats app':'Whatsapp',
    'Pay Online':'Plătiți online',
    'Owed': 'Datorită',
    'Edit': 'Editați',
    'Update': 'Actualizați',
    'Remaining': 'Rămas',
    'Pay with Card': 'Plătiți cu cardul',
    'Pay with Bank': 'Plătiți cu Banca',
    'Pay': 'A plati',
    'Pay by IBAN': 'Plata prin IBAN',
};