/**
 * German
 */
export const de = {
    'Transaction History': 'Verlauf der Transaktionen',
    'Total Debit': 'Gesamtbelastung',
    'Total Credit': 'Gesamtpunktzahl',
    'Net Balance': 'Nettosaldo',
    'Date': 'Datum',
    'Debit': 'Lastschrift',
    'Credit': 'Kredit',
    'Balance': 'Nettosaldo',
    'Customer will give': 'Kunde schuldet',
    'Customer will get': 'Kunde erhält',
    'Dr': '-',
    'Cr': '+',
    'Latest': 'Neueste',
    'Months': {
        "Jan": "Jan",
        "Feb": "Feb",
        "Mar": "Mar",
        "Apr": "Apr",
        "May": "May",
        "Jun": "Jun",
        "Jul": "Jul",
        "Aug": "Aug",
        "Sep": "Sep",
        "Oct": "Okt",
        "Nov": "Nov",
        "Dec": "Dec"
    },
    'Call':'Anruf',
    'Whats app':'Whatsapp',
    'Pay Online': 'Online bezahlen',
    'Owed': 'Online bezahlen',
    'Edit': 'Bearbeiten',
    'Update': 'Aktualisieren',
    'Remaining': 'Verbleibend',
    'Pay with Card': 'Mit Karte bezahlen',
    'Pay with Bank': 'Mit Bank bezahlen',
    'Pay': 'Zahlen',
    'Pay by IBAN': 'Zahlen Sie per IBAN',
};